<template>
  <div class="application">
    <div class="application-banner">
      <img :src="require('@imgs/application/web/banner.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/application/banner.png')" v-else />
      <div class="application-banner-title">
        <h3>VR应用中心</h3>
        <!-- <span>VR APPLICATION CENTER </span> -->
      </div>
    </div>
    <div class="application-breadcrumb">
      当前位置：<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: '/application' })">VR应用中心</span>
    </div>
    <div class="application-content">
      <div class="row">
        <div class="col-sm-3 col-12" style="display:flex;justify-content:center;margin-top:10px;"
          v-for="(item) in applications" :key="item.id">
          <application-item class="application-content-item" :info="item"></application-item>
        </div>
      </div>
      <page :total="total" @pageChange="getList"></page>
    </div>
  </div>
</template>
<script>
import applicationItem from './components/application-item'
import page from '@components/page/page'
export default {
  name: 'application',
  components: {
    applicationItem,
    page
  },
  data() {
    return {
      appTabItem: 'VR实训室',
      applications: [],
      total: 0
    }
  },
  created() {
    this.getList(1)
  },
  methods: {
    getList(pageNum) {
      this.$http({
        url: '/center/list',
        params: {
          pageNum,
          pageSize: 8
        }
      }).then(res => {
        this.applications = res.rows || []
        this.total = res.total
      })
    }
  }
}
</script>
<style scoped lang="scss">
.application {
  position: relative;

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 40px;
    background: #fff;
    padding: 30px;
  }

  &-header {
    margin-top: 60px;
    align-items: center;
  }

  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
  }

  &-tab {
    display: flex;

    &-item {
      padding: 5px 16px;
      font-size: 16px;
      border: 1px solid #eee;
      color: #666666;
      cursor: pointer;
    }

    &-item--active {
      background: #2870b6;
      color: #fff;
      border: 0;
    }
  }
}</style>